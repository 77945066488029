<template>
  <div v-if="page.length">

<!--  板块1  -->
    <div class="relative">
      <img
        :src="isMobile ? page[0].img_m : page[0].img"
        class="width-100"
        alt=""
      >
      <div
        class="absolute"
        :style="isMobile ? {
          top: '50px',
          left: '20px'
        } : {
          top: '200px',
          // left: `${80/3}vw`
          left: `${30/1.8}vw`
        }"
      >
        <div
          class="dib bd bb bd-000"
          :class="isMobile ? 'f20 pb10 mb10' : 'f32 b pb10 mb10'"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          data-aos="flip-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1000"
          v-html="page[0].t1"
        >

        </div>
        <div
          :class="isMobile ? 'f16' : 'f24 c-666'"
          style="font-weight:300"
          :style="isMobile ? { } : 'margin-top: 10px; width: 200px;'"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          data-aos="flip-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1000"
        >
          {{ page[0].t2 }}
        </div>

        <div :style="isMobile ? {
          marginTop:'20px'
        } : 'margin-top: 10px; width: 240px; '"
        >
          <div
            :class="isMobile ? 'f14' : 'f20 c-666'"
            style="font-weight:600; padding-bottom: 5px; margin-bottom: 5px;"
            :style="isMobile ? {
              fontWeight:'normal',
              border:'1px solid #9A9596',
              display:'inline-flex',
              padding: '4px 8px',
              color: '#6C696A'
            } : 'border: 1px solid #4B5F72; text-align:center; border-radius: 8px; margin-top:20px; padding:8px 0; '"
            v-vue-aos.once="{animationClass:'fadeInUp animated'}"
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
          >
            {{ page[0].t3 }}
          </div>

<!--          <div-->
<!--            v-if="!isMobile"-->
<!--            :class="isMobile ? 'f12' : 'f12 c-666'"-->
<!--            style="font-weight:lighter"-->
<!--            v-vue-aos.once="{animationClass:'fadeInUp animated'}"-->
<!--            data-aos="flip-left"-->
<!--            data-aos-easing="ease-out-cubic"-->
<!--            data-aos-duration="1000"-->
<!--          >-->
<!--            {{ page[0].t4 }}-->
<!--          </div>-->
        </div>
      </div>
    </div>

<!--  板块2  -->

    <div class="relative">
      <img
        v-if="!isMobile"
        :src="page[1].img"
        class="width-100"
        alt=""
        v-vue-aos.once="{animationClass:'fadeInUp animated'}"
        data-aos="fade-left"
        data-aos-duration="1000"
      >

      <div
        :class="isMobile ? 'ptb40 plr20' : 'absolute'"
        :style="{
          top: '180px',
          right: `${30/3.1}vw`
        }"
      >
        <div
          class=" pb10"
          :class="isMobile ? 'f28' : 'f40'"
          :style="isMobile ? 'text-align:center;' : 'text-align:center;'"
          v-html="isMobile ? page[1].t1 : page[1].t1"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          data-aos="fade-up"
          data-aos-easing="ease-out-cubic"
        />

        <div
          class=""
          :class="isMobile ? 'f20 mb30' : 'f24 pb20 mb30'"
          :style="isMobile ? 'text-align:center; margin-top:-5px;' : 'text-align:center; border-bottom:1px solid #b1b1b1;'"
          v-html="isMobile ? page[1].t3 : page[1].t3"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          data-aos="fade-up"
          data-aos-easing="ease-out-cubic"
        />

        <div
          v-if="isMobile"
          style="height: 2px; width: 80px; background: #FBC036; margin: 0 auto 40px auto; "
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          data-aos="fade-up"
        ></div>

        <div
          v-if="!isMobile"
          style="display:flex; text-align: center;"
        >
          <div
            v-for="(item,i) in page[1].t4"
            :key="i"
            class=""
            :class="isMobile ? 'f16 mb10' : 'f20 mb20'"
            :style="i === page[1].t2.length - 1 ? '' : 'font-weight:lighter;'"
            v-vue-aos.once="{animationClass:'fadeInUp animated'}"
            data-aos="fade-up"
            data-aos-easing="ease-out-cubic"
          >
            <div style="margin: 0 10px;">
              <img
                :src="item.icon"
                style="margin-bottom: 15px; width: 120px;"
                v-vue-aos.once="{animationClass:'fadeInUp animated'}"
                data-aos="fade-up"
              />
              <div>{{item.name}}</div>
            </div>
          </div>
        </div>
        <div v-else
          style="width: 100%; display: flex; flex-wrap: wrap; justify-content: flex-start;"
        >
          <div
            v-for="(item,i) in page[1].t4"
            :key="i"
            class="mItemChild"
            :class="isMobile ? 'f16 mb10' : 'f20 mb20'"
            :style="i === page[1].t2.length - 1 ? '' : 'font-weight:lighter;'"
            v-vue-aos.once="{animationClass:'fadeInUp animated'}"
            data-aos="fade-up"
            data-aos-easing="ease-out-cubic"
            style="flex: 1; margin: 0 5px 25px 0; width: calc((100% - 10px) / 3); min-width: calc((100% - 10px) / 3);max-width: calc((100% - 10px) / 3);"
          >
            <div style="margin: 0 6px; text-align: center;">
              <img
                :src="item.icon"
                style="margin-bottom: 15px; width: 85%;"
                v-vue-aos.once="{animationClass:'fadeInUp animated'}"
                data-aos="fade-up"
              />
              <div>{{item.name}}</div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="isMobile"
        style="width: 100%; margin-top: -10px; text-align: center;"
      >
        <img
          :src="page[1].img_m"
          style="width: 90%;"
          alt=""
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          data-aos="fade-up"
          data-aos-easing="ease-out-cubic"
        >
      </div>
    </div>


<!--  板块3  -->
    <div class="relative">
      <img
        :src="isMobile ? page[2].img_m : page[2].img"
        class="width-100"
        alt=""
        v-vue-aos.once="{animationClass:'fadeInUp animated'}"
        data-aos="fade-left"
        data-aos-duration="1000"
      >

      <div
        :class="isMobile ? 'ptb40 plr20 absolute' : 'absolute'"
        :style="isMobile ? {
          top: '20px',
          left: `0`,
          textAlign : 'center'
        } : {
          top: '200px',
          left: `${30/1.8}vw`
        }"
      >
        <div
          class=" pb10"
          :class="isMobile ? 'f28' : 'f40'"
          :style="isMobile ? '' : 'text-align:left; border-bottom:1px solid #485D70; padding:0 0 25px 0; margin-bottom:25px; width: 400px;'"
          v-html="isMobile ? page[2].t1: page[2].t1"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          data-aos="fade-left"
          data-aos-duration="1000"
        />

        <div
          class=" pb10"
          :class="isMobile ? 'f18' : 'f24'"
          :style="isMobile ? 'margin-top: 0px;' : 'text-align:left; '"
          v-html="isMobile ? page[2].t2.split(' ').join('<br>') : page[2].t2"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          data-aos="fade-left"
          data-aos-duration="1000"
        />

        <div
          v-if="isMobile"
          style="height: 2px; width: 80px; background: #FBC036; margin: 15px auto 25px auto; "
        ></div>

        <div
          :style="isMobile ? '' : 'height:2px; width:80px; background: #485D70;'"

          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          data-aos="fade-left"
          data-aos-duration="1000"
        ></div>

        <div
          class=" pb10"
          :class="isMobile ? 'f14' : 'f18'"
          :style="isMobile ? '' : 'text-align:left; margin-top:20px;width: 450px;'"
          v-html="isMobile ? page[2].t3.split(' ').join('<br>') : page[2].t3"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          data-aos="fade-left"
          data-aos-duration="1000"
        />

        <div
          v-if="isMobile"
          style="width: 100%; margin-top: 60px; text-align: center;"
        >
          <img
            :src="page[2].img_zs"
            style="width: 90%;"
            alt=""
            v-vue-aos.once="{animationClass:'fadeInUp animated'}"
            data-aos="fade-up"
            data-aos-easing="ease-out-cubic"
          >
        </div>

      </div>
    </div>

    <div
      v-if="!isMobile"
      :style="{ height: 'auto', padding: '230px 0',
        backgroundImage:`url(${page[4].img})`,
        backgroundSize:'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      }"
    >
      <div style="margin-left: 18%;">
        <div style="font-size: 30px; font-weight: bold; letter-spacing: 3px;"
             v-vue-aos.once="{animationClass:'fadeInUp animated'}"
             data-aos="fade-up"
             data-aos-delay="500">{{ page[4].t1 }}</div>
        <div style="font-size: 17px; letter-spacing: 1px; margin: 10px 0;"
             v-vue-aos.once="{animationClass:'fadeInUp animated'}"
             data-aos="fade-up"
             data-aos-delay="1000"
        >{{ page[4].t2 }}</div>
        <div style="display: flex; align-items:center; margin-top: 40px;">
          <div style="border: 1px solid #333333; padding: 12px 20px; margin-right: 20px; display: flex; max-width: 350px; align-items:center;"
               v-vue-aos.once="{animationClass:'fadeInUp animated'}"
               data-aos="zoom-in"
               data-aos-duration="2000"
          >
            <img :src="page[4].items[0].icon" style="width: 35px; height: 35px; margin-right: 15px;">
            <div>
              <div style="font-weight: bold; font-size: 24px;">{{page[4].items[0].t}}</div>
              <div style="font-size: 16px; line-height: 22px; margin-top: 5px;">{{page[4].items[0].d}}</div>
            </div>
          </div>
          <div style="border: 1px solid #333333; padding: 12px 20px; margin-right: 20px; display: flex; max-width: 350px; align-items:center;"
               v-vue-aos.once="{animationClass:'fadeInUp animated'}"
               data-aos="zoom-in"
               data-aos-duration="2000">
            <img :src="page[4].items[1].icon" style="width: 35px; height: 35px; margin-right: 15px;">
            <div>
              <div style="font-weight: bold; font-size: 24px;">{{page[4].items[1].t}}</div>
              <div style="font-size: 16px; line-height: 22px; margin-top: 5px;">{{page[4].items[1].d}}</div>
            </div>
          </div>
          <div style="border: 1px solid #333333; padding: 12px 20px; display: flex; max-width: 350px; align-items:center;"
               v-vue-aos.once="{animationClass:'fadeInUp animated'}"
               data-aos="zoom-in"
               data-aos-duration="2000">
            <img :src="page[4].items[2].icon" style="width: 35px; height: 35px; margin-right: 15px;">
            <div>
              <div style="font-weight: bold; font-size: 24px;">{{page[4].items[2].t}}</div>
              <div style="font-size: 16px; line-height: 22px; margin-top: 5px;" v-html="page[4].items[2].d"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else
         :style="{ height: 'auto', padding: '70px 0 150px 0',
        backgroundImage:`url(${page[4].img_m})`,
        backgroundSize:'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      }"
    >
      <div style="text-align: center; font-size: 26px; font-weight: bold; letter-spacing: 1px;"
           v-vue-aos.once="{animationClass:'fadeInUp animated'}"
           data-aos="fade-up"
           data-aos-delay="500"
      >{{ page[4].t1 }}</div>
      <div style="text-align: center; font-size: 15px; letter-spacing: 1px; margin: 5px 0 0 0;"
           v-vue-aos.once="{animationClass:'fadeInUp animated'}"
           data-aos="fade-up"
           data-aos-delay="1000"
      >{{ page[4].t2 }}</div>
      <div style="width: 75%; margin: 40px auto 0 auto">
        <div style="border: 1px solid #666; padding: 5px 20px 10px 20px; margin-right: 20px; display: flex; width: 100%; margin-bottom: 10px;  align-items:center;"
             v-vue-aos.once="{animationClass:'fadeInUp animated'}"
             data-aos="zoom-in"
             data-aos-duration="2000"
        >
          <img :src="page[4].items[0].icon" style="width: 35px; height: 35px; margin-right: 15px;">
          <div>
            <div style="font-weight: bold; font-size: 20px;">{{page[4].items[0].t}}</div>
            <div style="font-size: 15px; line-height: 20px; margin-top: 5px;">{{page[4].items[0].d}}</div>
          </div>
        </div>
        <div style="border: 1px solid #666; padding: 5px 20px 10px 20px; margin-right: 20px; width: 100%;  margin-bottom: 10px; display: flex; align-items:center;"
             v-vue-aos.once="{animationClass:'fadeInUp animated'}"
             data-aos="zoom-in"
             data-aos-duration="3000">
          <img :src="page[4].items[1].icon" style="width: 35px; height: 35px; margin-right: 15px;">
          <div>
            <div style="font-weight: bold; font-size: 20px;">{{page[4].items[1].t}}</div>
            <div style="font-size: 15px; line-height: 20px; margin-top: 5px;">{{page[4].items[1].d}}</div>
          </div>
        </div>
        <div style="border: 1px solid #666; padding: 5px 20px 10px 20px; display: flex; width: 100%;  margin-bottom: 10px; align-items:center;"
             v-vue-aos.once="{animationClass:'fadeInUp animated'}"
             data-aos="zoom-in"
             data-aos-duration="4000">
          <img :src="page[4].items[2].icon" style="width: 35px; height: 35px; margin-right: 15px;">
          <div>
            <div style="font-weight: bold; font-size: 20px;">{{page[4].items[2].t}}</div>
            <div style="font-size: 15px; line-height: 20px; margin-top: 5px;">{{page[4].items[2].d}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page: []
    }
  },

  async created () {
    const { data } = await this.fetch('/chanpin-rsw.json')
    this.page = data
  },

  methods: {
  }
}
</script>

<style lang="less">
.mItemChild:nth-child(3n){margin-right: 0!important;}
</style>
